<template>
  <div class="absolute bottom-0 right-0 mx-2 mb-12 text-black flex z-50 text-xs select-none">
    <div class="block my-2 bg-white rounded-lg p-2">
      <div
        class="hover:underline cursor-pointer text-gray-800 text-right"
        @click.prevent.stop="close"
      >
        Close
        <fa-icon
          icon="xmark"
          class="fa-fw"
        />
      </div>

      <template v-if="renderPostProcessingAvailable">
        <h5 class="font-bold uppercase mt-1 pt-1 border-t border-gray-400 text-gray-600 leading-loose">
          Effects
        </h5>

        <div class="flex -mx-1 my-1">
          <template v-if="renderPostProcessingAvailable">
            <div
              class="flex-1 btn btn-xs p-1 mx-1 text-center"
              :class="{'positive' : !renderPostProcessingEnabled }"
              @click.prevent="changeRenderPostProcessing(false)"
            >
              Disabled
            </div>
            <div
              class="flex-1 btn btn-xs p-1 mx-1 text-center"
              :class="{'positive' : renderPostProcessingEnabled }"
              @click.prevent="changeRenderPostProcessing(true)"
            >
              Enabled
            </div>
          </template>
          <div
            v-else
            class="flex-1 btn disabled btn-xs p-1 mx-1 text-center"
          >
            Not Available
          </div>
        </div>
      </template>
      <h5 class="font-bold uppercase mt-1 pt-1 border-t border-gray-400 text-gray-600 leading-loose">
        Quality
      </h5>
      <div class="flex -mx-1 my-1">
        <div
          class="flex-1 btn btn-xs p-1 mx-1 text-center"
          :class="{'positive' : renderQuality === 'low' }"
          @click.prevent="changeRenderQuality('low')"
        >
          Low
        </div>
        <div
          class="flex-1 btn btn-xs p-1 mx-1 text-center"
          :class="{'positive' : renderQuality === 'mid' }"
          @click.prevent="changeRenderQuality('mid')"
        >
          Standard
        </div>
        <div
          class="flex-1 btn btn-xs p-1 mx-1 text-center"
          :class="{'positive' : renderQuality === 'high' }"
          @click.prevent="changeRenderQuality('high')"
        >
          High
        </div>
        <!-- <div @click.prevent="changeRenderQuality('auto')" class="flex-1 btn btn-xs p-1 mx-1 text-center" :class="{'positive' : renderQuality === 'auto' }">Auto</div> -->
      </div>

      <h5 class="font-bold uppercase mt-1 pt-1 border-t border-gray-400 text-gray-600 leading-loose">
        View Mode
      </h5>
      <div class="flex -mx-1 my-1">
        <div
          class="flex-1 btn btn-xs p-1 px-2 mx-1 text-center"
          :class="{'positive' : renderMode === 'wireframe' }"
          @click.prevent="changeRenderMode('wireframe')"
        >
          Wire
        </div>
        <div
          class="flex-1 btn btn-xs p-1 px-2 mx-1 text-center"
          :class="{'positive' : renderMode === 'normal' }"
          @click.prevent="changeRenderMode('normal')"
        >
          Normals
        </div>
        <div
          class="flex-1 btn btn-xs p-1 px-2 mx-1 text-center"
          :class="{'positive' : renderMode === 'solid' }"
          @click.prevent="changeRenderMode('solid')"
        >
          Solid
        </div>
        <div
          class="flex-1 btn btn-xs p-1 px-2 mx-1 text-center"
          :class="{'positive' : renderMode === 'default' }"
          @click.prevent="changeRenderMode('default')"
        >
          Render
        </div>
      </div>
      <h5
        class="cursor-pointer mt-1 pt-1 border-t border-gray-400 text-right text-gray-700"
        @click.prevent="resetAll"
      >
        Reset to default
      </h5>
    </div>
  </div>
</template>
<script>
import menuMixin from './menuMixin.js'

export default {
  name: 'ViewerSettingsMenu',

  mixins: [menuMixin],

  props: {

    visible: {
      type: Boolean,
      default: false
    },

    renderMode: {
      type: String,
      default: 'default'
    },

    renderQuality: {
      type: String,
      default: 'mid'
    },

    renderPostProcessingAvailable: {
      type: Boolean,
      default: false
    },

    renderPostProcessingEnabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {

    changeRenderPostProcessing (state) {
      this.$emit('change-render-postprocessing', state)
    },

    changeRenderMode (mode) {
      this.$emit('change-render-mode', mode)
    },

    changeRenderQuality (quality) {
      this.$emit('change-render-quality', quality)
    },

    resetAll () {
      this.changeRenderMode('default')
      this.changeRenderQuality('auto')
    },

    close () {
      this.$emit('close')
    }
  }

}
</script>
